import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'

import Section1Bg1 from './../../../assets/images/about-section-1-bg-1.svg'
import Section1Bg2 from './../../../assets/images/about-section-1-bg-2.svg'
import Mission1 from './../../../assets/images/about-mission-1.svg'
import Mission2 from './../../../assets/images/about-mission-2.svg'
import Mission3 from './../../../assets/images/about-mission-3.svg'

import Section3Bg1 from './../../../assets/images/section-2-bg-1.svg'

const About = () => {
    return (
        <>
            <section className="page-banner">
                <Container>
                    <div className="page-title">
                        About Us
                    </div>
                    <div className="page-subtitle">
                        Bridging gaps in global supply chain tracking. GTAI has rapidly evolved
                        <br />into a hub of technological excellence.
                    </div>
                </Container>
            </section>
            <section className="about-section-1">
                <Container>
                    <Row>
                        <Col md={5}>
                            <div className="text-large">
                                About GTAI
                            </div>
                            <div className="text-small pt-4">
                                Our team, spread across the United States and Portugal, is a diverse blend of expert engineers, visionary strategists, and industry pioneers. We are united by a common goal: to deliver revolutionary solutions that enhance transparency, security, and efficiency in supply chains worldwide.At GTAI, we are proud to have developed a world-class global tracking ecosystem, unparalleled in its integration of AI and blockchain technology. This ecosystem doesn't just track assets; it transforms them into smart, connected elements of a larger, more intelligent network.
                                <br />
                                <br />
                                Our solutions are designed to empower businesses, big and small, to navigate the complexities of global logistics with ease and confidence.
                            </div>
                            <div className="mt-4 pt-4">
                                <NavLink to="/products">
                                    <Button variant="primary">Explore More</Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md={7}>
                            <img className="bg-1" src={Section1Bg1} alt="Bg 1" width="100%" />
                            <img className="bg-2" src={Section1Bg2} alt="Bg 2" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="about-section-2">
                <Container>
                    <div className="text-center text-large">
                        The GTAI Mission
                    </div>
                    <Row className="mt-4">
                        <Col md={4} className="mission-card text-center">
                            <img src={Mission1} alt="Mission 1" width="100%" />
                            <div className="text-medium mt-4">
                                Redefining Standard
                            </div><div className="text-small mt-2">
                                Our mission is to redefine the standards of excellence in the supply chain and logistics industry. We are committed to
                                delivering unparalleled hardware &
                                software solutions.
                            </div>
                        </Col>
                        <Col md={4} className="mission-card text-center">
                            <img src={Mission2} alt="Mission 2" width="100%" />
                            <div className="text-medium mt-4">
                                Revolutionising Businesses
                            </div><div className="text-small mt-2">
                                Our mission is to revolutionize how businesses interact with their supply chains, bringing a new level of intelligence and interconnectedness to every node of the global logistics network.
                            </div>
                        </Col>
                        <Col md={4} className="mission-card text-center">
                            <img src={Mission3} alt="Mission 3" width="100%" />
                            <div className="text-medium mt-4">
                                Rooting Innovation
                            </div><div className="text-small mt-2">
                                Our mission is deeply rooted in innovation and sustainability. We are dedicated to pioneering solutions that not only drive economic growth but also promote environmental responsibility and social integrity.
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section-7">
                <Container>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6} className="ps-4">
                            <div className="text-large ps-4">
                                Elevate your Logistics
                                <br />
                                today with GTAI
                                <br />
                                Technology
                            </div>
                            <div className="text-small pt-4 ps-4">
                                Contact GTAI today to revolutionize your logistics operations with the GTAI Air Freight Tracker. Elevate your supply chain efficiency, ensure cargo integrity, and enhance customer satisfaction with unparalleled real-time visibility and actionable data insights.
                                <br />
                                <br />
                                Embrace the future of logistics with GTAI's innovative solution, designed to optimize your air and ground freight processes, reduce operational costs, and drive your business towards
                                sustainable success.
                            </div>
                            <div className="mt-4 pt-4 ps-4">
                                <NavLink to="/products">
                                    <Button variant="primary">Explore More</Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="about-section-3">
                <Container>
                    <div className="text-center text-large">
                        We lead the evolution of product tracking
                        <br /> with secure, smart solutions.
                    </div>
                    <div className="text-center mt-4 pt-4">
                        <NavLink to="/contact">
                            <Button variant="success"> Request A Demo </Button>
                        </NavLink>
                    </div>
                    <img className="bg-1" src={Section3Bg1} alt="Bg 1" />
                </Container>
            </section>
        </>
    )
}

export default About