import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import Company1Src from './../../../assets/images/vodafone.svg'
// import Company2Src from './../../../assets/images/siemens.png'
import Company3Src from './../../../assets/images/cascais.svg'
import Company4Src from './../../../assets/images/yonest.webp'
import Company5Src from './../../../assets/images/goodpan.png'

import AiSrc from './../../../assets/images/ai.svg'
import BlockchainSrc from './../../../assets/images/blockchain.svg'
import NetworkSrc from './../../../assets/images/network.svg'

import BorderSrc from './../../../assets/images/border-dotted.svg'

import Maritime from './../../../assets/images/section-5-maritime.svg'

import Section6Industries1 from './../../../assets/images/section-6-industries-1.svg'
import Section6Industries2 from './../../../assets/images/section-6-industries-2.svg'
import Section6Industries3 from './../../../assets/images/section-6-industries-3.svg'
import Section6Industries4 from './../../../assets/images/section-6-industries-4.svg'

import Section8Bg1 from './../../../assets/images/section-8-bg-1.svg'
import Section8Bg2 from './../../../assets/images/section-8-bg-2.svg'

const Home = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }

    return (
        <>
            <section className="banner home2">
                <video className="banner-video" id="videoPlayer" loop autoPlay playsInline muted>
                    <source src="https://gtai-assets.s3.amazonaws.com/video/banner.mp4" type="video/mp4" />
                </video>
                <div className="banner-content">
                    <Container>
                        <Row>
                            <Col md={12} className="home2-content">
                                <div>
                                    <div className="banner-text-large">
                                        Navigating the Future
                                    </div>
                                    <div className="banner-text-small">
                                        Elevating Supply Chains through the Fusion of Blockchain, IoT, and
                                        <br />
                                        AI for Supply Chain and Logistics
                                    </div>
                                </div>
                                <div>
                                    <div className="banner-btn-group mt-4 pt-4">
                                        <NavLink to="/products">
                                            <Button variant="primary" className="m-4 ms-0">Explore More</Button>
                                        </NavLink>
                                        <NavLink to="/contact">
                                            <Button variant="success" className="m-4 ms-0">Get Started</Button>
                                        </NavLink>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <section className="section-1">
                <Container>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="text-large">
                                Discover
                                <br />
                                Global Tracking AI
                            </div>
                            <div className="text-small pt-4">
                                Welcome to Global Tracking AI (GTAI), your trusted partner in the revolutionizing supply chain management with a focus on providing unparalleled traceability, transparency, and trackability solutions. We excel in offering top-tier hardware and software powered by AI and blockchain for unmatched traceability and security.
                                <br /><br />
                                Our solutions are designed to enhance decision-making and elevate operational standards.
                            </div>
                            <div className="mt-4 pt-4">
                                <NavLink to="/products">
                                    <Button variant="primary">Explore More</Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section-2">
                <Container>
                    <Row>
                        <div className="text-large text-center">
                            Choice For The
                            <br />
                            World's Leading Companies
                        </div>
                        <div className="company-box text-large text-center mt-4">
                            <img src={Company1Src} alt="Company1" className="mx-4" width="160px" />
                            <img src={Company3Src} alt="Company3" className="mx-4" width="160px" />
                            <img src={Company4Src} alt="Company4" className="mx-4" width="160px" />
                            <img src={Company5Src} alt="Company5" className="mx-4" width="160px" />
                        </div>
                        <div className="text-center">
                            <div className="text-large">
                                Why Global Tracking AI
                            </div>
                            <div className="text-small">
                                Global Tracking AI works tirelessly to revolutionize supply chain management and improve visibility within the
                                <br />supply chain across the board. We use the world's most cutting-edge hardware and software to serve freight forwarders, cargo insurance companies
                            </div>
                        </div>
                        <Row className="mx-0 mt-4 pt-4">
                            <Col md={4} className="position-relative">
                                <img src={BorderSrc} alt="Border" className="border-image" />
                                <div className="border-dotted text-center">
                                    <img src={AiSrc} alt="Ai" className="mx-4" height="100px" />
                                    <div className="text-medium mt-4 mb-2">
                                        AI Engine Solution
                                    </div>
                                    <div className="text-small">
                                        Global Tracking uses AI to forecast demand,
                                        <br /> plan routes, automate warehouse, and
                                        <br /> analyze data.
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="position-relative">
                                <img src={BorderSrc} alt="Border" className="border-image" />
                                <div className="border-dotted text-center">
                                    <img src={BlockchainSrc} alt="Blockchain" className="mx-4" height="100px" />
                                    <div className="text-medium mt-4 mb-2">
                                        Private Blockchain Solution
                                    </div>
                                    <div className="text-small">
                                        Global Tracking uses Blockchain to store and
                                        <br /> share tamperproof data from IoT sensors,
                                        <br /> ensuring trust and compliance
                                        <br /> in logistics
                                    </div>
                                </div>
                            </Col>
                            <Col md={4} className="position-relative">
                                <img src={BorderSrc} alt="Border" className="border-image" />
                                <div className="border-dotted text-center">
                                    <img src={NetworkSrc} alt="Network" className="mx-4" height="100px" />
                                    <div className="text-medium mt-4 mb-2">
                                        IoT Network Solutions
                                    </div>
                                    <div className="text-small">
                                        Our intelligent IoT devices for various
                                        <br /> modes of transportation offer unparalleled
                                        <br /> asset monitoring through real-time
                                        <br /> data on location, condition, and
                                        <br />  environmental factors.
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-center mt-4 pt-4">
                            <NavLink to="/contact">
                                <Button variant="primary">Connect With Us</Button>
                            </NavLink>
                        </div>
                    </Row>
                </Container >
            </section>
            <section className="section-3">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="text-large">
                                AI Engine Solution
                            </div>
                            <div className="text-small pt-4">
                                Global Tracking AI utilizes artificial intelligence and
                                <br />machine learning for a variety of use cases,
                                <br />including route optimization, automating warehousing,
                                <br />creating predictive analytics for demand forecasting, and more.
                                <br /><br />
                                These services save millions for our customers in both
                                <br />time and money.
                            </div>
                            <div className="mt-4 pt-4">
                                <NavLink to="/products">
                                    <Button variant="primary">Explore More</Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                </Container>
            </section>
            <section className="section-4">
                <Container>
                    <Row>
                        <Col md={7}></Col>
                        <Col md={5}>
                            <div className="text-large">
                                Private Blockchain
                                <br />Solution
                            </div>
                            <div className="text-small pt-4">
                                GTAI uses blockchain technology to store tamper-proof
                                <br />data collected by our IoT devices. We also use smart
                                <br />contracts to enhance transparency across different
                                <br />parties within the supply chain such as freight forwarders,
                                <br />insurance brokers, and more.
                            </div>
                            <div className="mt-4 pt-4">
                                <NavLink to="/products">
                                    <Button variant="primary">Explore More</Button>
                                </NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section-5">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="text-large mt-4 pt-4">
                                IoT Solutions
                            </div>
                            <div className="text-small pt-4">
                                GTAI Connect is an IoT solution featuring four devices for cargo tracking and monitoring. The GTAI Robust Gateway provides accurate GPS tracking and seamless communication, while the GTAI Robust Sensor extends Bluetooth range. The GTAI Air Freight Tracker and GTAI Maritime Device deliver near-real-time alerts for location and environmental conditions, ensuring comprehensive monitoring for air and ocean shipments.
                            </div>
                            <Row className="stats mt-4 pt-4">
                                <Col md={6}>
                                    <div className="title">5K+</div>
                                    <div className="sub-title">Trackers Sold</div>
                                </Col>
                                <Col md={6}>
                                    <div className="title">1M+</div>
                                    <div className="sub-title">Real-Time Alerts Delivered</div>
                                </Col>
                                <Col md={6} className="mt-4 pt-4">
                                    <div className="title">10K+</div>
                                    <div className="sub-title">Satisfied Customers</div>
                                </Col>
                            </Row>
                            <div className="mt-4 pt-4">
                                <NavLink to="/products">
                                    <Button variant="primary">Explore More</Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex">
                            <img src={Maritime} alt="Maritime" width="320px" className="maritime" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section-6">
                <Container>
                    {/* <Row className="pb-4">
                        <div className="text-large text-center">
                            We lead the evolution of product tracking
                            <br />
                            with secure, smart solutions.
                        </div>
                        <div className="text-center mt-4 pt-4">
                            <NavLink to="/contact">
                                <Button variant="success"> Get Started </Button>
                            </NavLink>
                        </div>
                    </Row> */}
                    <Row className="mt-4 pt-4">
                        <div className="text-large text-center mt-4 pt-4">
                            Our Industries
                        </div>
                        <div className="text-small text-center">
                            Our solutions provide comprehensive visibility in logistics with end-to-end tracking, ensuring real-time
                            <br />
                            monitoring from origin to destination, while employing AI and IoT.
                        </div>
                    </Row>
                    <Carousel
                        infinite={true}
                        responsive={responsive}
                        className="mt-4 pt-4"
                    >
                        <div className="industries">
                            <img src={Section6Industries1} alt="Industries 1" width="100%" />
                            <div className="content">
                                <div className="title">
                                    Global Logistics
                                </div>
                                <div className="value">
                                    Comprehensive Visibility
                                    <br />Optimized Operations
                                </div>
                            </div>
                        </div>
                        <div className="industries">
                            <img src={Section6Industries2} alt="Industries 2" width="100%" />
                            <div className="content">
                                <div className="title">
                                    Pharmaceuticals
                                </div>
                                <div className="value">
                                    Compliance And Safety
                                    <br />Temperature Control
                                </div>
                            </div>
                        </div>
                        <div className="industries">
                            <img src={Section6Industries3} alt="Industries 3" width="100%" />
                            <div className="content">
                                <div className="title">
                                    Agriculture
                                </div>
                                <div className="value">
                                    Supply Chain Transparence
                                    <br />Quality Assurance
                                </div>
                            </div>
                        </div>
                        <div className="industries">
                            <img src={Section6Industries4} alt="Industries 4" width="100%" />
                            <div className="content">
                                <div className="title">
                                    Semiconductor
                                </div>
                                <div className="value">
                                    Precision Tracking
                                    <br />Inventory Management
                                </div>
                            </div>
                        </div>
                    </Carousel>
                    <div className="text-center mt-4 pt-4 ps-4">
                        <NavLink to="/products">
                            <Button variant="primary">Explore More</Button>
                        </NavLink>
                    </div>
                </Container >
            </section>
            <section className="section-8">
                <Container className="position-relative">
                    <img src={Section8Bg1} alt="Demo" width="100%" />
                    <img src={Section8Bg2} alt="AI Dashbboard" className="ai-dashboard" />
                    <Row className="content">
                        <div className="text-large text-center">
                            Real-Time Tracking
                        </div>
                        <div className="text-small text-center">
                            Connect with us to transform your supply chain management
                        </div>
                        <div className="text-center mt-4 pt-4">
                            <NavLink to="/contact">
                                <Button variant="primary"> Get a Free Demo </Button>
                            </NavLink>
                        </div>
                    </Row>
                </Container>
            </section>
            {/* <section className="section-9">
                <Container>
                    <img src={Section9Bg3} alt="background-2" className="background-2" />
                    <Row>
                        <div className="text-large text-center">
                            Supply Chain Management
                        </div>
                        <div className="text-small text-center">
                            Our solutions provide comprehensive visibility in logistics with end-to-end tracking, ensuring real-time
                            <br />  monitoring from origin to destination, while employing AI and IoT
                        </div>
                        <div className="position-relative">
                            <img src={Section9Bg2} alt="background-3" className="background-3" width="100%" />
                            <div className="feature-box box-1">
                                <div className="title-icon">
                                    <img src={Section9Feature1} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    The GTAI SecureLink is a robust Software Development Kit to maintain the confidentiality and integrity of IoT data.
                                </div>
                            </div>
                            <div className="feature-box box-2">
                                <div className="title-icon">
                                    <img src={Section9Feature2} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    The IoT Smart Device Ecosystem provides real time tracking that allows you to improve operations while taking control of your assets.
                                </div>
                            </div>
                            <div className="feature-box box-3">
                                <div className="title-icon">
                                    <img src={Section9Feature3} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    GTAI ChainGuard uses Hyperledger Fabric Blockchain technology ton strengthen security in every transaction.
                                </div>
                            </div>
                            <div className="feature-box box-4">
                                <div className="title-icon">
                                    <img src={Section9Feature4} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    GTAI TechHub is a cloud based dashboard that offers a user-friendly approach to securing data driven insights and improving operations.
                                </div>
                            </div>
                            <div className="feature-box box-5">
                                <div className="title-icon">
                                    <img src={Section9Feature5} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    This advanced AI engine offers automation and predictive insights to spearhead efficiency and proactive decision making.
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-4 pt-4">
                            <NavLink to="/contact">
                                <Button variant="success"> Get Started </Button>
                            </NavLink>
                        </div>
                    </Row>
                </Container >
            </section> */}
        </>
    )
}

export default Home