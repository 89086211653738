import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'

import Section1Bg1 from './../../../assets/images/industries-section-1-bg-1.svg'

import Section2Bg1 from './../../../assets/images/industries-section-2-bg-1.svg'
import Section2Bg2 from './../../../assets/images/industries-section-2-bg-2.svg'
import Section2Bg3 from './../../../assets/images/industries-section-2-bg-3.svg'
import Section2Bg4 from './../../../assets/images/industries-section-2-bg-4.svg'

import Section3Bg1 from './../../../assets/images/industries-section-3-bg-1.svg'

import Section4Bg1 from './../../../assets/images/industries-section-4-bg-1.svg'

import Section5Bg1 from './../../../assets/images/industries-section-5-bg-1.svg'

import SectionConnectBg1 from './../../../assets/images/section-2-bg-1.svg'

const Industries = () => {
    return (
        <>
            <section className="page-banner">
                <Container>
                    <div className="page-title">
                        Industries
                    </div>
                    <div className="page-subtitle">
                        Across diverse range industries Transforming
                        <br />The Supply Chain Landscape
                    </div>
                </Container>
            </section>
            <section className="industries-section-1">
                <Container>
                    <Row>
                        <Col md={5}>
                            <div className="text-large">
                                Global Logistics
                            </div>
                            <div className="text-small pt-4">
                                We target logistic companies specializing in the ground transportation of goods by offering in-transit and multi-item GPS monitoring and tracking.
                                <br />
                                <br />
                                Through our solutions, we provide increased security, improved overall customer experience, efficient operational systems and optimum cost effectiveness.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Comprehensive Visibility</span>
                                    <br />
                                    Our Solutions Offer End-To-End Visibility And Real-Time Tracking.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Optimized Operations</span>
                                    <br />
                                    We Streamline Operations, Reduce Transit Times, & Enhance Cargo Security Using AI and IoT.
                                </div>
                            </div>
                        </Col>
                        <Col md={7}>
                            <img className="bg-1" src={Section1Bg1} alt="Bg 1" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="industries-section-2">
                <img className="bg-1" src={Section2Bg1} alt="Bg 1" width="100%" />
                <img className="bg-2" src={Section2Bg2} alt="Bg 1" width="100%" />
                <img className="bg-3" src={Section2Bg3} alt="Bg 1" width="100%" />
                <img className="bg-4" src={Section2Bg4} alt="Bg 1" width="100%" />
                <Container>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="text-large">
                                Precious Goods
                            </div>
                            <div className="text-small pt-4">
                                We target high-end retailers and manufacturers by offering advanced security, quality monitoring and on th dot tracking of their transport operations.
                                <br />
                                <br />
                                The precious goods market is a multi-billion doller industry and with the right solutions, this can be protected.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Enhanced Security</span>
                                    <br />
                                    We Provide Unmatched Security To Deter Theft ANd Loss.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Condition Monitoring</span>
                                    <br />
                                    We Ensure Integrity And Preservation Of Precious Goods During Transit.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="industries-section-3">
                <Container>
                    <Row>
                        <Col md={12} className="text-center mb-4 pb-4">
                            <div className="text-large">
                                Precision and Efficiency
                            </div>
                            <div className="text-small">
                                Our specialized solutions are designed to meet each industry's
                                <br />unique challenges and demand.
                            </div>
                            <div className="text-center mt-4 pt-4">
                                <NavLink to="/contact">
                                    <Button variant="success">Get Started</Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="text-large mt-4 pt-4">
                                Pharmaceuticals
                            </div>
                            <div className="text-small pt-4">
                                Securing the supply and logistics in the pharmaceutical industry is paramount to maintain public health, ensure patient safety, and sustain the industry's ability to innovate and contribute to
                                global healthcare advancements.
                                <br />
                                Our solutions ensure precise tracking in air and ground transport.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Compliance And Safety</span>
                                    <br />
                                    Our Solutions Ensure Compliance With Stringent Industry Regulations, Maintaining
                                    Safety And Integrity Of Pharmaceutical Products.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Temperature Control</span>
                                    <br />
                                    Specialized Iot Sensors Monitor And Report On Critical Factors Like Temperature
                                    And Humidity
                                </div>
                            </div>
                        </Col>
                        <Col md={7}>
                            <img className="bg-1" src={Section3Bg1} alt="Bg 1" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="industries-section-4">
                <img className="bg-1" src={Section4Bg1} alt="Bg 1" />
                <Container>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="text-large">
                                Agriculture
                            </div>
                            <div className="text-small pt-4">
                                Securing the supply and logistics in the agriculture industry is paramount for ensuring food security, economic stability, and sustainable development. A resilient and efficient agricultural supply chain is vital for addressing global challenges and with our intelligent solutions, this can be achieved.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Supply Chain Transparence</span>
                                    <br />
                                    From Farm To Table, Our Technology Provides Complete Transparency In
                                    Agricultural Supply Chains
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Quality Assurance</span>
                                    <br />
                                    Real-Time Data Collection And Analysis Ensure The Quality And Safety Of
                                    Agricultural Products
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="industries-section-5">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="text-large mt-4 pt-4">
                                Semiconductor
                            </div>
                            <div className="text-small pt-4">
                                The interconnected and global nature of the semiconductor supply chain emphasizes the importance of resilience and risk mitigation to maintain a reliable and robust technology infrastructure.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Precision Tracking</span>
                                    <br />
                                    Our precise tracking tools monitor and manage the movement of materials with exceptional accuracy.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Inventory Management</span>
                                    <br />
                                    Our Ai-Driven Solutions Optimize Inventory Levels, Reducing Shortages And Surpluses.
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <img className="bg-1" src={Section5Bg1} alt="Bg 1" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="about-section-3">
                <Container>
                    <div className="text-center text-large">
                        We lead the evolution of product tracking
                        <br /> with secure, smart solutions.
                    </div>
                    <div className="text-center mt-4 pt-4">
                        <NavLink to="/contact">
                            <Button variant="success"> Request A Demo </Button>
                        </NavLink>
                    </div>
                    <div>
                        <img className="bg-1" src={SectionConnectBg1} alt="Bg 1" />
                        <div className="bg-2" />
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Industries