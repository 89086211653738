import { useState } from 'react'
import { Container, Row, Col, Form, Button, Modal, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

import Section1Bg1 from './../../../assets/images/contact-section-1-bg-1.svg'
import Section3Bg1 from './../../../assets/images/section-2-bg-1.svg'

const About = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const [showLoader, setShowLoader] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)

    const onSubmit = (data) => {
        setShowLoader(true)
        fetch('https://api.globaltracking.ai/public/contactUs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            return res.json()
        }).then(res => {
            setShowLoader(false)
            if (res.status) {
                reset()
                setShowSuccessModal(true)
            } else {
                setShowErrorModal(true)
            }
        }).catch(error => {
            setShowLoader(false)
            setShowErrorModal(true)
        })
    }

    const onError = (errors) => {
        console.log(errors)
    }

    const handleModalClose = () => {
        setShowSuccessModal(false)
        setShowErrorModal(false)
    }

    return (
        <>
            <section className="page-banner">
                <Container>
                    <div className="page-title">
                        Get In touch
                    </div>
                    <div className="page-subtitle">
                        Elevating Supply Chains through the Fusion of Blockchain,
                        <br />IoT, and AI for Supply Chain and Logistics
                    </div>
                </Container>
            </section>
            <section className="contact-section-1">
                <Container>
                    <img className="bg-1" src={Section1Bg1} alt="Bg 1" width="100%" />
                    <Row>
                        <Col md={6}>
                            <div className="contact-info-box mt-4 pt-4 ms-4 ps-4">
                                <div className="text-medium mt-4 pt-4">
                                    Contact Information
                                </div>
                                <div className="text-small mt-3 list-icon-tick">
                                    Sales Inquiries:
                                    <br />Email: <a href="mailto:info@globaltracking.ai?subject=Inquiry&body=Hello, I would like to ask about...">
                                        info@globaltracking.ai
                                    </a>
                                </div>
                                <div className="text-small mt-3 list-icon-tick">
                                    Technical Support:
                                    <br />Email: <a href="mailto:support@globaltracking.ai?subject=Inquiry&body=Hello, I would like to ask about...">
                                        support@globaltracking.ai
                                    </a>
                                </div>
                            </div>
                            <div className="contact-info-box mt-4 pt-4 ms-4 ps-4">
                                <div className="text-medium">
                                    Our Locations
                                </div>
                                <div className="text-small mt-3 list-icon-map">
                                    US Address:
                                    <br />6601 Owen’s Dr Suite 270, Pleasanton, CA 94588, USA
                                </div>
                                <div className="text-small mt-3 list-icon-map">
                                    PT Address:
                                    <br />Tec Labs, Campus FCUL, Campo Grande 016, 1749-016
                                    <br />Lisboa, Portugal
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="contact-form-box">
                                <div className="text-medium">
                                    Fill Your Details
                                </div>
                                <div className="text-small mb-4">
                                    We are Pioneering the Next Generation of Supply Chain Solutions.
                                </div>
                                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                                    <Row>
                                        <Form.Group as={Col} md={6} controlId="validationCustom01">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="First Name *"
                                                    {...register('firstName', { required: 'First Name is required' })}
                                                />
                                                <Form.Label>First Name *</Form.Label>
                                                {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="validationCustom02">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Last Name *"
                                                    {...register('lastName', { required: 'Last Name is required' })}
                                                />
                                                <Form.Label>Last Name *</Form.Label>
                                                {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="validationCustom02">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Company Email *"
                                                    {...register('email', {
                                                        required: 'Company Email is required',
                                                        pattern: {
                                                            value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                            message: 'Invalid email address'
                                                        }
                                                    })}
                                                />
                                                <Form.Label>Company Email *</Form.Label>
                                                {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="validationCustom02">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Mobile No. *"
                                                    {...register('mobile', { required: 'Mobile No. is required' })}
                                                />
                                                <Form.Label>Mobile No. *</Form.Label>
                                                {errors.mobile && <Form.Text className="text-danger">{errors.mobile.message}</Form.Text>}
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md={12} controlId="validationCustom02">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Company Name *"
                                                    {...register('companyName', { required: 'Company Name is required' })}
                                                />
                                                <Form.Label>Company Name *</Form.Label>
                                                {errors.companyName && <Form.Text className="text-danger">{errors.companyName.message}</Form.Text>}
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="validationCustom02">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Country"
                                                    {...register('country')}
                                                />
                                                <Form.Label>Country</Form.Label>
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md={6} controlId="validationCustom02">
                                            <Form.Floating>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Company Size"
                                                    {...register('companySize')}
                                                />
                                                <Form.Label>Company Size</Form.Label>
                                            </Form.Floating>
                                        </Form.Group>
                                        <Form.Group as={Col} md={12} controlId="validationCustom02">
                                            <Form.Floating>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    placeholder="What would you like to discuss?"
                                                    style={{ height: '150px' }}
                                                    {...register('message', { required: 'Message is required' })}
                                                />
                                                <Form.Label>Message *</Form.Label>
                                                {errors.message && <Form.Text className="text-danger">{errors.message.message}</Form.Text>}
                                            </Form.Floating>
                                        </Form.Group>
                                    </Row>
                                    <Button type="submit" className="mt-4">Submit Now</Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >
            <section className="about-section-3">
                <Container>
                    <div className="text-center text-large">
                        We lead the evolution of product tracking
                        <br /> with secure, smart solutions.
                    </div>
                    <div className="text-center mt-4 pt-4">
                        <Button variant="success"> Request A Demo </Button>
                    </div>
                    <img className="bg-1" src={Section3Bg1} alt="Bg 1" />
                </Container>
            </section>
            {
                showLoader && (
                    <div className="full-page-loader">
                        <Spinner animation="border" variant="light" role="status">
                            <span className="sr-only"></span>
                        </Spinner>
                    </div>
                )
            }
            <Modal size="lg" centered show={showSuccessModal} onHide={handleModalClose}>
                <Modal.Header closeButton className="border-0 p-0">
                    <Modal.Title className="m-auto text-center">
                        Thank You For Contacting Us
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p style={{ fontSize: '16px' }} className="text-muted">
                        We have received your message. We'll reach
                        <br />you out immediately!
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 text-center p-0">
                    <Button variant="success" className="m-auto" onClick={handleModalClose}>
                        Back To Home
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" centered show={showErrorModal} onHide={handleModalClose}>
                <Modal.Header closeButton className="border-0 p-0">
                    <Modal.Title className="m-auto text-center text-danger" style={{ fontSize: '40px' }}>
                        Error!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <p style={{ fontSize: '16px' }} className="text-muted">
                        Something went wrong!
                        <br />
                        Please try again.
                    </p>
                </Modal.Body>
                <Modal.Footer className="border-0 text-center p-0">
                    <Button variant="danger" className="m-auto" onClick={handleModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default About