import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav, Button } from 'react-bootstrap'

import Logo from './../../assets/logo/logo.png'
import Icon from './../../assets/logo/icon.svg'

import FacebookSrc from './../../assets/logo/facebook.svg'
import InstagramSrc from './../../assets/logo/instagram.svg'
import YoutubeSrc from './../../assets/logo/youtube.svg'

const AppFooter = () => {
    return (
        <footer>
            <div className="sticky-content">
                <img src={Icon} className="app-icon " alt="logo" />
                <div>
                    <div className="lebel-1">Ensure Shipments</div>
                    <div className="lebel-2">Arrive On Time And In Full</div>
                </div>
                <NavLink to="/contact">
                    <Button variant="dark">Get In Touch</Button>
                </NavLink>
                <NavLink to="/contact">
                    <Button variant="success">Get a Free Demo</Button>
                </NavLink>
            </div>
            <img src={Logo} className="App-logo" alt="logo" />
            <Nav>
                <NavLink className="nav-link" to="/home">Home</NavLink>
                <NavLink className="nav-link" to="/about">About</NavLink>
                <NavLink className="nav-link" to="/industries">Industries</NavLink>
                <NavLink className="nav-link" to="/products">Products</NavLink>
                <NavLink className="nav-link" to="/contact">Contact</NavLink>
            </Nav>
            <div className="social-icon">
                <hr />
                <div className="social-link">
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com">
                        <div><img src={FacebookSrc} alt="Facebook" /></div>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com">
                        <div><img src={InstagramSrc} alt="Instagram" /></div>
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://www.youtube.com">
                        <div><img src={YoutubeSrc} alt="Youtube" /></div>
                    </a>
                </div>
            </div>
            <div className="copyright">
                Copyright 2024, <span>GTAI</span>, All Right Reserved. <a target="_blank" href="/">Terms and Condition</a> | <a target="_blank" href="/"> Privacy Policy</a>
            </div>
        </footer>
    )
}

export default AppFooter