import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Button } from 'react-bootstrap'

import Section1Bg1 from './../../../assets/images/products-section-1-bg-1.svg'

import Section2Bg1 from './../../../assets/images/products-section-2-bg-1.svg'
import Section2Bg2 from './../../../assets/images/industries-section-2-bg-2.svg'
import Section2Bg3 from './../../../assets/images/industries-section-2-bg-3.svg'
import Section2Bg4 from './../../../assets/images/industries-section-2-bg-4.svg'

import Section3Bg1 from './../../../assets/images/products-section-3-bg-1.svg'
import Section3Bg2 from './../../../assets/images/products-section-3-bg-2.svg'

import Section4Bg1 from './../../../assets/images/section-8-bg-1.svg'
import Section4Bg2 from './../../../assets/images/section-8-bg-2.svg'

import Section5Bg1 from './../../../assets/images/products-section-5-bg-1.svg'

import SectionConnectBg1 from './../../../assets/images/section-2-bg-1.svg'

import Section9Bg2 from './../../../assets/images/section-9-bg-2.svg'
import Section9Bg3 from './../../../assets/images/section-9-bg-3.svg'
import Section9Feature1 from './../../../assets/images/section-9-feature-1.svg'
import Section9Feature2 from './../../../assets/images/section-9-feature-2.svg'
import Section9Feature3 from './../../../assets/images/section-9-feature-3.svg'
import Section9Feature4 from './../../../assets/images/section-9-feature-4.svg'
import Section9Feature5 from './../../../assets/images/section-9-feature-5.svg'

const Products = () => {
    useEffect(() => {
        document.body.style.backgroundColor = '#0b2348'
        return () => {
            document.body.style.backgroundColor = ''
        }
    }, [])

    return (
        <>
            {/* <section className="page-banner">
                <Container>
                    <div className="page-title">
                        Products
                    </div>
                    <div className="page-subtitle">
                        Revolutionizing Supply Chain Management.
                        <br />Bringing You Solutions That Redefine Efficiency, Security, And Transparency.
                    </div>
                </Container>
            </section> */}
            <section className="section-9">
                <Container>
                    <img src={Section9Bg3} alt="background-2" className="background-2" />
                    <Row>
                        <div className="text-large text-center">
                            Supply Chain Management
                        </div>
                        <div className="text-small text-center">
                            Our solutions provide comprehensive visibility in logistics with end-to-end tracking, ensuring real-time
                            <br />  monitoring from origin to destination, while employing AI and IoT
                        </div>
                        <div className="position-relative">
                            <img src={Section9Bg2} alt="background-3" className="background-3" width="100%" />
                            <div className="feature-box box-1">
                                <div className="title-icon">
                                    <img src={Section9Feature1} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    The GTAI SecureLink is a robust Software Development Kit to maintain the confidentiality and integrity of IoT data.
                                </div>
                            </div>
                            <div className="feature-box box-2">
                                <div className="title-icon">
                                    <img src={Section9Feature2} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    The IoT Smart Device Ecosystem provides real time tracking that allows you to improve operations while taking control of your assets.
                                </div>
                            </div>
                            <div className="feature-box box-3">
                                <div className="title-icon">
                                    <img src={Section9Feature3} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    GTAI ChainGuard uses Hyperledger Fabric Blockchain technology ton strengthen security in every transaction.
                                </div>
                            </div>
                            <div className="feature-box box-4">
                                <div className="title-icon">
                                    <img src={Section9Feature4} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    GTAI TechHub is a cloud based dashboard that offers a user-friendly approach to securing data driven insights and improving operations.
                                </div>
                            </div>
                            <div className="feature-box box-5">
                                <div className="title-icon">
                                    <img src={Section9Feature5} alt="Feature-1" />
                                </div>
                                <div className="text-small">
                                    This advanced AI engine offers automation and predictive insights to spearhead efficiency and proactive decision making.
                                </div>
                            </div>
                        </div>
                    </Row>
                </Container >
            </section>
            <section className="products-section-1">
                <Container>
                    <Row>
                        <Col md={12} className="text-center mb-4 pb-4">
                            <div className="text-large">
                                Product Line Up
                            </div>
                            <div className="text-small">
                                Explore our suite of innovative products - the breakthroughs in
                                <br /> supply chain management.
                            </div>
                            <div className="text-center my-4 py-4">
                                <NavLink to="/contact">
                                    <Button variant="success">Get Started</Button>
                                </NavLink>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="text-large">
                                GTAi Thinkedge Ai
                            </div>
                            <div className="text-small pt-4">
                                This device offer predictive analytics, automation, and real-time insights.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Features</span>
                                    <br />
                                    Machine Learning Algorithms For Data Analysis Predictive Analytics For Proactive Decision-Making Continuous Learning For Evolving
                                    Ai Solutions.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Benefits</span>
                                    <br />
                                    Anticipate Market Trends And Adapt Quickly Increase Efficiency And Reduce Operational Risks Gain A Competitive Edge With Smarter, Faster Decision-Making.
                                </div>
                            </div>
                        </Col>
                        <Col md={7}>
                            <img className="bg-1" src={Section1Bg1} alt="Bg 1" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="products-section-2">
                <img className="bg-1" src={Section2Bg1} alt="Bg 1" width="100%" />
                <img className="bg-2" src={Section2Bg2} alt="Bg 1" width="100%" />
                <img className="bg-3" src={Section2Bg3} alt="Bg 1" width="100%" />
                <img className="bg-4" src={Section2Bg4} alt="Bg 1" width="100%" />
                <Container>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="text-large">
                                GTAi Chain Guard
                            </div>
                            <div className="text-small pt-4">
                                This utilizes Hyperledger Fabric Blockchain technology for enhanced data security and integrity.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Features</span>
                                    <br />
                                    Permissioned blockchain network for secure transactions Smart contract functionality for automated compliance Immutable ledger for traceability and transparency.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Benefits</span>
                                    <br />
                                    Unmatched security and privacy for your data Enhanced trust and reliability in transactions Streamlined operations with automated smart contracts.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="products-section-3">
                <Container>
                    <Row>
                        <Col md={5} className="gtai-connect">
                            <div className="text-large mt-4 pt-4">
                                GTAi Connect
                            </div>
                            <div className="text-small pt-4">
                                This device is designed for seamless integration across Air, Ocean, and Ground transportation.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Features</span>
                                    <br />
                                    Real-time tracking and monitoring
                                    <br />Advanced sensors for environmental and condition data
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Benefits</span>
                                    <br />
                                    Enhanced visibility and control over your assets Improved operational efficiency and reduced costs Higher reliability and reduced risk of asset loss or damage.
                                </div>
                            </div>
                        </Col>
                        <Col md={7}>
                            <img className="bg-1" src={Section3Bg1} alt="Bg 1" width="100%" />
                            <img className="bg-2" src={Section3Bg2} alt="Bg 2" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="products-section-4">
                <img className="bg-1" src={Section4Bg1} alt="Bg 1" />
                <img className="bg-2" src={Section4Bg2} alt="Bg 2" />
                <Container>
                    <Row>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <div className="text-large">
                                GTAi Tech Hub
                            </div>
                            <div className="text-small pt-4">
                                This is our user-friendly, cloud-based dashboard that offers comprehensive insights into logistics operations.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Features</span>
                                    <br />
                                    Intuitive interface with customizable dashboards Data analytics and reporting tools Integrated with GTAI Connect for cohesive asset management.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Benefits</span>
                                    <br />
                                    Simplified Decision-Making Through Data-Driven Insights Improved Operational Oversight And Management Enhanced Collaboration And Communication Across Team.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="products-section-5">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="text-large mt-4 pt-4">
                                GTAi Securelink
                            </div>
                            <div className="text-small pt-4">
                                This is a robust Software Development Kit designed for securing data from external IoT devices.
                            </div>
                            <br />
                            <div className="text-small">
                                <div className="list-icon-tick">
                                    <span className="text-white">Features</span>
                                    <br />
                                    Advanced encryption and security protocols Easy integration with various IoT devices and platforms Continuous updates for staying ahead of security threats.
                                </div>
                                <div className="list-icon-tick mt-2">
                                    <span className="text-white">Benefits</span>
                                    <br />
                                    Ensure the integrity and confidentiality of your IoT data Reduce the risk of cyber threats and data breaches Foster customer trust with superior data protection.
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <img className="bg-1" src={Section5Bg1} alt="Bg 1" width="100%" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="about-section-3">
                <Container>
                    <div className="text-center text-large">
                        We lead the evolution of product tracking
                        <br /> with secure, smart solutions.
                    </div>
                    <div className="text-center mt-4 pt-4">
                        <NavLink to="/contact">
                            <Button variant="success"> Request A Demo </Button>
                        </NavLink>
                    </div>
                    <img className="bg-1" src={SectionConnectBg1} alt="Bg 1" />
                </Container>
            </section>
        </>
    )
}

export default Products